<template>
  <div id="member-subscription-create">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Title Donation Payment</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Enter payment details for manually received</h4></v-card-subtitle
      >

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once.</li>
            <li>
              After paying regular subscription, choose additional subcription.
            </li>
            <li>
              Late fee is optional but paying late fee is required to get voting
              rights.
            </li>
            <li>
              After completing the payment, kindly select your members for whom
              the payment was made.
            </li>
          </ol>
        </v-container>
        <h6>* indicates required field</h6>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" md="3">
                <label>*Title Type</label>
                <v-select
                  @change="showForm"
                  :reduce="(option) => option.value"
                  :items="TitleTypeIdOptions"
                  :rules="TitleTypeIdRules"
                  v-model="TitleTypeId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>*Member Type</label>
                <v-select
                  @change="showForm"
                  :reduce="(option) => option.value"
                  :items="MemberTypeIdOptions"
                  :rules="MemberTypeIdRules"
                  v-model="MemberTypeId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="NewTitleFlag == false">
                <label>*Membership Id</label>
                <v-text-field
                  v-model="MembershipId"
                  :rules="MembershipIdRules"
                  hint="Enter the membership id"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap v-if="NewTitleFlag == false">
              <v-col align="center" cols="12" md="12">
                <v-btn
                  type="submit"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  target="_blank"
                  color="#8950FC"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-form
          ref="form2"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container v-if="ShowFormFlag">
            <v-row wrap>
              <!-- <v-col cols="12" sm="6" md="3">
                <label>*Year</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="JciYearCodeOptionsLoading"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  v-model="JciYearCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="6" md="3">
                <label>Paid Date</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="PaidDate"
                      label="Paid Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="PaidDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>*Title</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="TitleCodeOptionsLoading"
                  :items="TitleCodeOptions"
                  :rules="TitleCodeRules"
                  v-model="TitleCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>*Member Photo</label>
                <v-row>
                  <v-col cols="9">
                    <div class="col-lg-9 col-xl-6">
                      <div
                        class="image-input image-input-outline"
                        id="kt_profile_avatar"
                      >
                        <div
                          class="image-input-wrapper"
                          :style="{ backgroundImage: `url(${current_photo})` }"
                        ></div>
                        <label
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <i class="fa fa-pen icon-sm text-muted"></i>
                          <input
                            type="file"
                            ref="ProfilePicture"
                            name="profile_avatar"
                            accept=".png, .jpg, .jpeg"
                            @change="onFileChange($event)"
                          />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title="Remove avatar"
                          @click="current_photo = null"
                        >
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                      <span class="form-text text-muted"
                        >Allowed file types: png, jpg, jpeg.</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*LMC Register No</label>
                <v-text-field
                  v-model="RegisterNo"
                  :rules="RegisterNoRules"
                  hint="Enter the LMC Register No"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*LMC Receipt No</label>
                <v-text-field
                  v-model="ReceiptNo"
                  :rules="ReceiptNoRules"
                  hint="Enter the Receipt No"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Paid Amount</label>
                <v-text-field
                  v-model="PaidAmount"
                  :rules="PaidAmountRules"
                  hint="Enter the paid amount"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Zone</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*LOM</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*First Name</label>
                <v-text-field
                  v-model="FirstName"
                  :rules="FirstNameRules"
                  hint="Enter the member first name"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Last Name</label>
                <v-text-field
                  v-model="LastName"
                  :rules="LastNameRules"
                  hint="Enter the member last name"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>*Line 1</label>
                <v-text-field
                  v-model="Street1"
                  :rules="Street1Rules"
                  hint="Enter the line 1 of address"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="8">
                <label>Line 2</label>
                <v-text-field
                  v-model="Street2"
                  :rules="Street2Rules"
                  hint="Enter the line 2 of address"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Area</label>
                <v-text-field
                  v-model="Area"
                  :rules="AreaRules"
                  hint="Enter the area of address"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*State</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateIdOptionsLoading"
                  :items="StateIdOptions"
                  :rules="StateIdRules"
                  v-model="StateId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*District</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictIdOptionsLoading"
                  :items="DistrictIdOptions"
                  :rules="DistrictIdRules"
                  v-model="DistrictId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*City</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityIdOptionsLoading"
                  :items="CityIdOptions"
                  :rules="CityIdRules"
                  v-model="CityId"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Pincode</label>
                <v-text-field
                  v-model="Pincode"
                  type="number"
                  :rules="PincodeRules"
                  :counter="6"
                  hint="Enter the pincode"
                  v-mask="'######'"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Mobile No</label>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  type="number"
                  :counter="10"
                  hint="Enter the mobile no"
                  v-mask="'##########'"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>Email Id</label>
                <v-text-field
                  v-model="EmailId"
                  :rules="EmailIdRules"
                  type="email"
                  hint="Enter the email id"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>GST Number</label>
                <v-text-field
                  v-model="Member.LomGstNumber"
                  :rules="GstNumberRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-container class="py-0" v-if="SearchFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      SearchResultFlag: false,
      ShowFormFlag: false,
      NewTitleFlag: true,
      NotesFlag: false,
      rows: [],
      tableColumns1: [],
      tableColumns2: [
        { text: "Date", value: "PaidDateTxt", width: "13%" },
        { text: "Description", value: "Description", width: "35%" },
        { text: "Late Fee", value: "FineAmountTxt", width: "13%" },
        { text: "To be paid", value: "FineNotPaid", width: "13%" },
        { text: "No of members", value: "Qty", width: "13%" },
        { text: "Sub Total", value: "SubTotal", width: "13%" },
      ],
      selected: [],
      search: "",
      CurrentYearId: 0,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      TabFlag3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      OrderType: 1,

      TitleCodeRules: [(v) => !!v || "Title is required"],
      TitleCode: "",
      TitleCodeOptions: [],
      TitleCodeOptionsLoading: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumberFlag: 2,
      GstNumberFlagRules: [
        (v) => !!v || "Does your LOM has GST number field required",
      ],
      GstNumberFlagOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      GstNumber: "",
      GstNumberRules: [],

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      MemberId: "",
      JciMemberTypeId: 1,

      TitleTypeId: "",
      TitleTypeIdRules: [(v) => !!v || "Title type should not be empty "],
      TitleTypeIdOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "New Title" },
        { value: 2, text: "Upgrade Title" },
      ],

      MemberTypeId: "",
      MemberTypeIdRules: [(v) => !!v || "Member type should not be empty"],
      MemberTypeIdOptions: [],
      MemberTypeIdOptionsLoading: false,

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      RegisterNo: "",
      RegisterNoRules: [(v) => !!v || "Register No is required"],

      ReceiptNo: "",
      ReceiptNoRules: [(v) => !!v || "Receipt No is required"],

      PaidAmount: "",
      PaidAmountRules: [(v) => !!v || "Paid amount is required"],

      TransactionFees: 0,
      TransactionFeesRules: [],

      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Area: "",
      AreaRules: [],

      MobileNo: "",
      MobileNoRules: [
        // (v) => !!v || "Mobile No is required"
      ],

      EmailId: "",
      EmailIdRules: [
        // (v) => !!v || "E-mail is required",
        // (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      current_photo: null,
    };
  },
  computed: {},
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
      this.getZoneCodeOptions();
    },
    TitleCodeOptions: function () {
      console.log("watch TitleCodeOptions");
      this.LoadingFlag = false;
      this.TitleCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "" && ZoneCode != (null || undefined)) {
        this.getLomCodeOptions();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
      var tr = this.Member;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.LomCode = tr.LomId;
      }
    },
    PaidDate: function () {
      console.log("watch PaidDate");
      this.getTitleCodeOptions();
    },
    MemberTypeIdOptions: function () {
      console.log("watch MemberTypeIdOptions");
      this.MemberTypeIdOptionsLoading = false;
    },
    Member: function () {
      console.log("watch Member");
      var Member = this.Member;
      console.log("Member=" + JSON.stringify(Member));
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      var StateId = this.StateId;
      console.log("StateId=" + StateId);
      if (StateId != "" && StateId != (null || undefined)) {
        this.LoadingFlag = true;
        this.DistrictIdOptionsLoading = true;
        this.getDistrictIdOptions();
      } else {
        this.DistrictId = "";
      }
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
      var tr = this.Member;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var DistrictId = tr.DistrictId;
        console.log("DistrictId=" + DistrictId);
        this.DistrictId = DistrictId;
      }
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      var DistrictId = this.DistrictId;
      console.log("DistrictId=" + DistrictId);
      if (DistrictId != "" && DistrictId != (null || undefined)) {
        this.LoadingFlag = true;
        this.CityIdOptionsLoading = true;
        this.getCityIdOptions();
      } else {
        this.CityId = "";
      }
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
      var tr = this.Member;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.CityId = tr.CityId;
      }
    },
    Member: function () {
      console.log("watch Member");
      this.MemberId = "";
      var tr = this.Member;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      this.MemberFlag = n1 > 0 ? true : false;
      this.current_photo = n1 > 0 ? tr.ProfilePic : null;
      this.MemberId = n1 > 0 ? tr.MemberId : "";
      this.ZoneCode = n1 > 0 ? tr.CurrentZoneId : "";
      this.LomCode = n1 > 0 ? tr.LomId : "";
      var FirstName = n1 > 0 ? tr.FirstName : "";
      var MiddleName = n1 > 0 ? tr.MiddleName : "";
      this.FirstName = n1 > 0 ? FirstName + " " + MiddleName : "";
      this.LastName = n1 > 0 ? tr.LastName : "";
      this.current_photo = n1 > 0 ? tr.ProfilePic : "";
      this.MobileNo = n1 > 0 ? tr.PrimaryMobile : "";
      this.EmailId = n1 > 0 ? tr.EmailIdTxt : "";
      this.Street1 = n1 > 0 ? tr.Street1 : "";
      this.Street2 = n1 > 0 ? tr.Street2 : "";
      this.Area = n1 > 0 ? tr.Area : "";
      var StateId = n1 > 0 ? tr.StateId : "";
      var DistrictId = n1 > 0 ? tr.DistrictId : "";
      var CityId = n1 > 0 ? tr.CityId : "";
      console.log(
        "StateId=" +
          StateId +
          ", DistrictId=" +
          DistrictId +
          ", CityId=" +
          CityId
      );
      this.StateId =
        StateId != "" && StateId != (null || undefined) ? tr.StateId : "";
      this.Pincode = n1 > 0 ? tr.Pincode : "";
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    resetMember() {
      console.log("resetMember called");
      this.LomCode = "";
      this.Member = {
        MemberId: "",
        PrimaryMobile: "",
        EmailId: "",
      };
      this.MemberFlag = false;
    },
    resetSubscription() {
      this.SearchResultFlag = false;
      this.rows = [];
      this.PenaltySubscription = [];
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getMemberTypeIdOptions();
      this.getZoneCodeOptions();
      // this.getTitleCodeOptions();
      this.StateIdOptionsLoading = true;
      this.getStateIdOptions();
    },
    showForm() {
      console.log("showForm called");
      var MemberTypeId = this.MemberTypeId;
      var TitleTypeId = this.TitleTypeId;
      var JciMemberTypeId = this.JciMemberTypeId;
      var NewTitleFlag = TitleTypeId == 1 && MemberTypeId != 1 ? true : false;
      console.log(
        "MemberTypeId=" +
          MemberTypeId +
          ", TitleTypeId=" +
          TitleTypeId +
          ", JciMemberTypeId=" +
          JciMemberTypeId +
          ", NewTitleFlag=" +
          NewTitleFlag
      );
      this.NewTitleFlag = NewTitleFlag;
      this.ShowFormFlag = false;
      if (MemberTypeId != "") {
        if (MemberTypeId != JciMemberTypeId) {
          this.ShowFormFlag = NewTitleFlag == true ? true : false;
        }
      }
      this.$refs.form2.reset();
      this.Member = {};
      this.MemberId = "";
      this.MembershipId = "";
      this.current_photo = null;
    },
    getMemberTypeIdOptions() {
      console.log("getMemberTypeIdOptions called");
      this.MemberTypeIdOptionsLoading = true;
      var selectbox1_source = "MemberTypeId";
      var selectbox1_destination = "MemberTypeIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        CategoryMainId: 1,
        CategorySubId: [1, 22, 23],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTitleCodeOptions() {
      console.log("getTitleCodeOptions called");
      this.TitleCodeOptionsLoading = true;
      var selectbox1_source = "TitleCode";
      var selectbox1_destination = "TitleCodeOptions";
      var selectbox1_url = "api/wm/yearwise/titles/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        PaidDate: this.PaidDate,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/wm/year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCode = "";
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
        NotAvailable: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getStateIdOptions() {
      console.log("getStateIdOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = "StateId";
      var selectbox1_destination = "StateIdOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictIdOptions() {
      console.log("getDistrictIdOptions called");
      var StateId = this.StateId;
      console.log("StateId=" + StateId);
      if (StateId != "" && StateId != (null || undefined)) {
        this.LoadingFlag = true;
        var selectbox1_source = "DistrictId";
        var selectbox1_destination = "DistrictIdOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions() {
      console.log("getCityIdOptions called");
      var DistrictId = this.DistrictId;
      console.log("DistrictId=" + DistrictId);
      if (DistrictId != "" && DistrictId != (null || undefined)) {
        this.LoadingFlag = true;
        var selectbox1_source = "CityId";
        var selectbox1_destination = "CityIdOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        this.getMemberDetails();
      } else {
        var message = "";
        if (!validate1) {
          message = "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var TitleTypeId = this.TitleTypeId;
      var MemberTypeId = this.MemberTypeId;
      console.log(
        "TitleTypeId=" + TitleTypeId + ", MemberTypeId=" + MemberTypeId
      );

      var MemberTypeId = TitleTypeId == 1 ? "" : MemberTypeId;
      console.log("MemberTypeId=" + MemberTypeId);

      if (validate1) {
        this.SearchFlag = true;
        this.ShowFormFlag = false;
        this.Member = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: 3,
          additional: {
            MembershipId: this.MembershipId,
            MemberTypeId: MemberTypeId,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.Member = records[0];
              thisIns.toast("success", output);
              thisIns.ShowFormFlag = true;
              // thisIns.resetForm();
            } else {
              // thisIns.MemberTypeId = 23;
              thisIns.ShowFormFlag = thisIns.TitleTypeId == 1 ? true : false;
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill required fields";
        /*
        if (JciYearCode == "") {
          message += "Year should not be empty. ";
        }
        */
        this.toast("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      var validate2 = this.$refs.form2.validate();
      console.log("validate1=" + validate1 + ", validate2=" + validate2);

      var PaidDate = this.PaidDate;
      var current_photo = this.current_photo;

      console.log("PaidDate=" + PaidDate + ", current_photo=" + current_photo);

      if (validate1 && validate2 && PaidDate != "") {
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);

        /*
        var tempContribution = this.Contribution;
        let Contribution = tempContribution.filter(function (e) {
          return e.Qty > 0;
        });
        console.log(Contribution);
        // var ContributionTxt = JSON.stringify(Contribution);

        Contribution.forEach((item) => {
          console.log("item=" + JSON.stringify(item));
          htmlTxt +=
            item["Description"] + " - <b>" + item["Qty"] + " Nos </b><br/>";
        });
        */
        var htmlTxt = "";
        htmlTxt += "<br/> Title <b> " + TitleName + "</b>";
        if (MemberTypeId == 1) {
          htmlTxt += "<br/> Membership Id <b> " + this.MembershipId + "</b>";
        }
        htmlTxt +=
          "<br/> Name <b> " + this.FirstName + " " + this.LastName + "</b>";
        htmlTxt += "<br/> Register No <b> " + this.RegisterNo + "</b>";
        htmlTxt += "<br/> Paid Date <b> " + this.PaidDate + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate2) {
          message += "Kindly fill the required fields. ";
        }
        if (PaidDate == "") {
          message += "Paid date should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      var validate2 = this.$refs.form2.validate();
      console.log("validate1=" + validate1 + ", validate2=" + validate2);

      if (validate1 && validate2) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lmc/title-contribution/offline-payment";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var MemberTypeId1 = this.MemberTypeId;
        var JciMemberTypeId = this.JciMemberTypeId;
        var MemberTypeId = MemberTypeId1 == JciMemberTypeId ? 1 : MemberTypeId1;
        console.log(
          "MemberTypeId1=" +
            MemberTypeId1 +
            ", JciMemberTypeId=" +
            JciMemberTypeId +
            ", MemberTypeId=" +
            MemberTypeId
        );

        var Member = {
          MemberTypeId: MemberTypeId,
          TitleTypeId: this.TitleTypeId,
          MemberName: this.MemberId,
          MembershipId: this.MembershipId,
          JciYearCode: this.JciYearCode,
          TitleName: this.TitleCode,
          PaidDate: this.PaidDate,
          RegisterNo: this.RegisterNo,
          ReceiptNo: this.ReceiptNo,
          PaidAmount: this.PaidAmount,
          ZoneName: this.ZoneCode,
          LomName: this.LomCode,
          MemberFirstName: this.FirstName,
          MemberLastName: this.LastName,
          Line1: this.Street1,
          Line2: this.Street2,
          Area: this.Area,
          StateId: this.StateId,
          DistrictId: this.DistrictId,
          CityId: this.CityId,
          Pincode: this.Pincode,
          MobileNo: this.MobileNo,
          EmailId: this.EmailId,
          GstNumber: this.GstNumber,
        };

        console.log("Member=" + JSON.stringify(Member));

        let upload = new FormData();
        upload.append("Member", JSON.stringify(Member));

        var ProfilePicture = this.$refs.ProfilePicture.files[0];

        if (ProfilePicture) {
          upload.append("ProfilePicture", ProfilePicture);
        }
        /*
        var upload = {
          member: member,
        };
        */
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, true);
              thisIns.ShowFormFlag = false;
              thisIns.resetForm();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1 || !validate2) {
          message += "Kindly fill the required fields";
        }
        if (n1 == 0) {
          message += "Atleast one member is required to transfer";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    },
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    if (this.$session.has("checkout")) {
      this.$session.remove("checkout");
    }
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#member-subscription-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
}
</style>